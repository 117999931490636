import { api } from '@/libraries/Api';

export default {
    namespaced: true,
    state: () => ({
        leagues: [],
        user: null,
    }),
    mutations: {
        set(state, leagues) {
            state.leagues = leagues;
        },
        setUser(state, user) {
            state.user = user;
        },
    },
    actions: {
        async get({commit, rootGetters}) {
            const edition = rootGetters["editions/getActive"];
            const data = await api.getLeagues(edition.id)
            commit('set', data);
            return data;
        },
        async user({commit}) {
            try {
                const data = await api.getUserLeague();
                commit('setUser', data);
                return data;
            } catch (error) {
                commit('setUser', null);
                return error;
            }
        }
    },
    getters: {
        getLeagues(state) {
            return state.leagues;
        },
        getLeague(state) {
            return (id) => {
                return state.leagues.find((l) => l.id === id)
            }
        },
        getUserLeague(state) {
            return state.user;
        },
    },
}