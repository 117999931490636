import {api} from '@/libraries/Api';

export default {
    namespaced: true,
    state: () => ({
        projects: [],
    }),
    mutations: {
        set(state, projects) {
            state.projects = projects;
        },
        setProject(state, project) {
            const projects = [...state.projects];
            let updated = false;
            for (let i = 0; i < projects.length; i++) {
                if (projects[i].id === project.id) {
                    projects[i] = project;
                    updated = true;
                    break;
                }
            }

            if (!updated) {
                projects.push(project);
            }

            state.projects = [...projects];
        }
    },
    actions: {
        async get({commit}) {
            const data = await api.getProjects();
            commit('set', data);
            return data;
        },
        async getProject({commit}, {projectId}) {
            const data = await api.getProject(projectId);
            commit('setProject', data);
            return data;
        },
        async addProject({commit, dispatch}, {post}) {
            const data = await api.createProject(post);
            commit('setProject', data);
            dispatch('notifications/get', null, {root:true});
            return data;
        },
        async updateProject({commit, dispatch}, {projectId, post}) {
            const data = await api.updateProject(projectId, post);
            commit('setProject', data);
            dispatch('notifications/get', null, {root:true});
            return data;
        },
        async addWordCount({commit, dispatch}, {project, post}) {
            const data = await api.createWordcount(project, post);
            commit('setProject', data.project);
            dispatch('notifications/get', null, {root:true})
            return data;
        },
        async updateWordCount({commit, dispatch}, {wordcountId, post}) {
            const data = await api.updateWordcount(wordcountId, post);
            commit('setProject', data.project);
            dispatch('notifications/get', null, {root:true});
            return data;
        },
        async deleteWordCount({commit, dispatch}, {wordcountId, projectId}) {
            const data = await api.deleteWordCount(wordcountId);
            dispatch('getProject', {projectId});
            dispatch('notifications/get', null, {root:true});
            return data;
        }
    },
    getters: {
        getActive(state, getters, rootState, rootGetters) {
            const edition = rootGetters["editions/getActive"];
            if (edition) {
                return state.projects.find(p => p.edition.id === edition.id);
            }
            return null;
        }
    },
}