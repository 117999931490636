import {api} from '@/libraries/Api';

export default {
    namespaced: true,
    state: () => ({
        editions: [],
    }),
    mutations: {
        set(state, editions) {
            state.editions = editions;
        }
    },
    actions: {
        async get({commit}) {
            const data = await api.getEditions()
            commit('set', data);
            return data;
        }
    },
    getters: {
        getWarmup(state) {
            return state.editions.find(e => e.is_warmup);
        },
        getActive(state) {
            return state.editions.find(e => e.is_active || e.is_warmup || e.is_cooloff);
        },
        getCooloff(state) {
            return state.editions.find(e => e.is_cooloff);
        },
        getActiveOnly(state) {
            return state.editions.find(e => e.is_active);
        },
        getAll(state)
        {
            return state.editions;
        },
        getLast(state)
        {
            return state.editions[state.editions.length - 1];
        }
    },
}