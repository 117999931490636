import {api} from '@/libraries/Api';

export default {
    namespaced: true,
    state: () => ({
        sidebarOpen: false,
    }),
    mutations: {
        set(state, payload) {
            const keys = Object.keys(payload);
            for (const k of keys) {
                state[k] = payload[k];
            }
        }
    },
    actions: {
        toggleSidebar({commit, state}) {
            commit('set', {sidebarOpen: !state.sidebarOpen});
        },
        setSidebarOpen({commit}, value) {
            commit('set', {sidebarOpen: value});
        },
    },
    getters: {
        isSidebarOpen(state) {
            return state.sidebarOpen;
        },
    },
}