import {api} from '@/libraries/Api';
import dayjs from 'dayjs';

export default {
    namespaced: true,
    state: () => ({
        unread: [],
        unshown: [],
        notifications: [],
    }),
    mutations: {
        set(state, notifications) {
            state.notifications = notifications;
        },
        setUnread(state, notifications) {
            state.unread = notifications.filter((n) => n.read_at === null);
        },
        setUnshown(state, notifications) {
            state.unshown = notifications.filter((n) => n.shown_at === null && n.read_at === null);
        },
        setSingle(state, {id, what}) {
            state[what] = [...state[what]].filter((n) => n.id !== id);
            state.notifications = [...state.notifications.map((n) => {
                if (n.id === id) {
                    if (what === 'unread') {
                        return {
                            ...n,
                            read_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                            shown_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                        };
                    } else if (what === 'unshown') {
                        return {
                            ...n,
                            shown_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                        };
                    }
                }
                return {...n};
            })];
        },
        empty(state) {
            state.notifications = [...state.notifications.map((n) => ({
                ...n,
                read_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                shown_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            }))];
            state.unread = [];
            state.unshown = [];
        },
    },
    actions: {
        async get({commit, dispatch}, payload) {
            const limit = payload && payload.limit ? payload.limit : undefined;
            const data = await api.getNotifications(limit);
            commit('set', data);
            commit('setUnread', data);
            commit('setUnshown', data);
            return data;
        },
        async setRead({commit}, {id}) {
            const notification = await api.setNotificationRead(id);
            commit('setSingle', {id, what: 'unread'});
            return notification;
        },
        async setShown({commit}, {id}) {
            const notification = await api.setNotificationShown(id);
            commit('setSingle', {id, what: 'unshown'});
            return notification;
        },
        async setAllRead({commit}) {
            const data = await api.setAllNotificationRead();
            commit('empty');
            return data;
        },
    },
    getters: {
        maxUnread() {
            return 10;
        },
        getNotifications(state, getters) {
            const max = getters['maxUnread'];
            if (state.notifications.length > max) {
                return state.notifications.slice(0, max);
            }
            return state.notifications;
        },
        getUnread(state) {
            return state.unread;
        },
        getUnreadNumber(state) {
            return state.unread.length;
        },
        getUnshown(state) {
            return state.unshown;
        },
        getGroupedUnshown(state) {
            const groups = {};
            const ids = {};
            for (const n of state.unshown) {
                if (!ids[n.type]) {
                    ids[n.type] = [];
                }
                if (!groups[n.type] && n.shown_in_modal) {
                    groups[n.type] = n;
                }
                ids[n.type].push(n.id);
            }

            const keys = Object.keys(groups);
            const modals = [];
            for (const k of keys) {
                modals.push({
                    type: k,
                    notification: groups[k],
                    count: ids[k].length,
                    ids: ids[k]
                });
            }

            return modals;
        },
        getFirstModal(state, getters) {
            const modals = getters['getGroupedUnshown'];
            if (modals.length > 0) {
                return modals[0];
            }
            return null;
        },
    },
}
