export const GRANT_TYPE = 'password';
export const CLIENT_ID = 2;
export const CLIENT_SECRET = 'kL5Y5dXJfCor567GdR8zaeANCHOci17w32Wd44rU';
export const DISCORD_CLIENT_ID = process.env.VUE_APP_DISCORD_ID_CLIENT;
export const DISCORD_API_ENDPOINT = 'https://discord.com/api';
export const DISCORD_SERVER_URL = 'https://discord.gg/5FWfauq';
export const FACEBOOK_GROUP_URL = 'http://bit.ly/NaNoWrita';
export const FACEBOOK_CRAWL_UNIT_URL = 'https://www.facebook.com/groups/wrimoitalia/learning_content/?filter=631269427526673';
export const INSTAGRAM_URL = 'https://www.instagram.com/wrimoitalia/';
export const PAYPAL_URL = 'https://paypal.me/pools/c/8ucKhG8bmg';
export const COOKIES_URL = 'https://www.wrimoitalia.it/cookies';
export const PRIMARY_COLOR = '#61C035';
export const PRIMARY_COLOR_LIGHT = '#81cd5d';
export const COLOR_DARK = '#79797a';
export const COLOR_LIGHT = '#c1c2c3';
export const COLOR_VERY_LIGHT = '#f1f2f4';
export const COOKIE_ANALYTICS_NAME = 'cookiePolicyAnalytics';
export const ANALYTICS_CODE = (process.env.VUE_APP_ANALYTICS_CODE !== '' ? process.env.VUE_APP_ANALYTICS_CODE : null);
export const BOT_GAME_ACTIVE = true;

