import Vuex from 'vuex';

import settings from './settings';
import user from './user';
import notifications from './notifications';
import editions from './editions';
import projects from './projects';
import badges from './badges';
import leagues from './leagues';

const debug = process.env.NODE_ENV !== 'production';

export default () => new Vuex.Store({
    modules: {
        settings,
        user,
        notifications,
        editions,
        projects,
        badges,
        leagues,
    },
    strict: debug,
    devtools: debug,
});
