import Auth from '@/libraries/Auth';
import {api} from '@/libraries/Api';
import dayjs from "dayjs";

export default {
    namespaced: true,
    state: () => ({
        user: Auth.getUser(),
        token: Auth.getToken(),
    }),
    mutations: {
        unset(state) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            state.user = null;
            state.token = null;
        },
        set(state, newUser) {
            localStorage.setItem('user', JSON.stringify(newUser));
            state.user = newUser;
        },
        setToken(state, newToken) {
            localStorage.setItem('token', JSON.stringify(newToken));
            state.token = newToken;
        }
    },
    actions: {
        async login({commit, dispatch}, {email, password, body}) {
            const data = await api.login(email, password, body);
            const token = {
                ...data,
                issued_at: dayjs().toISOString(),
            };
            commit('setToken', token);
            dispatch('get');
            return token;
        },
        async register({commit}, {body}) {
            return await api.register(body);
        },
        async get({commit}) {
            try {
                const data = await api.getMe();
                commit('set', data);
                return data;
            } catch (error) {
                if (error.status === 401) {
                    commit('unset');
                    window.location.url = '/login';
                }
                return Promise.reject(error);
            }
        },
        async update({ commit }, {body}) {
            const data = await api.updateMe(body);
            commit('set', data);
            return data;
        },
        async deleteAvatar({ commit }) {
            const data = await api.deleteMyAvatar();
            commit('set', data);
            return data;
        },
        async linkDiscord({commit, dispatch}, {body}) {
            const data = await api.linkDiscord(body);
            dispatch('notifications/get', null, {root:true});
            commit('set', data);
            return data;
        },
        async unlinkDiscord({commit, dispatch}) {
            const data = await api.unLinkDiscord();
            dispatch('notifications/get', null, {root:true});
            commit('set', data);
            return data;
        },
    },
    getters: {
        getUser(state) {
            return state.user;
        },
        isAdmin(state) {
            if (state.user) {
                return state.user.is_admin === 'admin';
            }
            return false;
        },
    },
}