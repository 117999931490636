import {api} from "@/libraries/Api";
import cloneDeep from "lodash.clonedeep";

export default {
    namespaced: true,
    state: () => ({
        badges: [],
    }),
    mutations: {
        set(state, badges) {
            state.badges = badges;
        }
    },
    actions: {
        async get({commit, rootGetters}) {
            const edition = rootGetters["editions/getActive"];
            const data = await api.getCurrentUserBadges(edition.id);
            commit('set', data);
            return data;
        },
    },
    getters: {
        getBadges(state) {
            const badges = cloneDeep(state.badges);

            for (const d of badges.data) {
                for (const g of d.groups) {
                    if (g.type === 'random') {
                        g.badges.sort((a, b) => {
                            if (a.is_unlocked === b.is_unlocked) {
                                return 0;
                            } else if (a.is_unlocked && !b.is_unlocked) {
                                return -1;
                            } else if (!a.is_unlocked && b.is_unlocked) {
                                return 1;
                            }
                        });
                        if (g.max_unlockable_badges) {
                            g.badges = g.badges.slice(0, g.max_unlockable_badges);
                        }
                    }
                }
            }

            return badges;
        },
    },
}