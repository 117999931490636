import {DISCORD_CLIENT_ID, DISCORD_API_ENDPOINT} from '@/config';
import dayjs from "dayjs";

export default class Auth {
    static isAuthenticated() {
        return (Auth.getToken() !== null);
    }

    static getToken() {
        let token = localStorage.getItem('token');
        if (token) {
            try {
                token = JSON.parse(token);
                const now = dayjs();
                const issued = dayjs(token.issued_at).add(token.expires_in, 'second');
                if (token.access_token && issued.isAfter(now)) {
                    return token;
                }
            } catch (e) {
                console.warn('Invalid token');
            }
        }
        return null;
    }

    static getUser() {
        const user = localStorage.getItem('user');
        return ['undefined', 'null'].includes(user) ? null : JSON.parse(user);
    }

    static unsetToken() {
        localStorage.removeItem('token');
    }

    static unsetUser() {
        localStorage.removeItem('user');
    }

    static unsetAll() {
        Auth.unsetToken();
        Auth.unsetUser();
    }

    static discordRedirect(scopes) {
        const generateRandomString = () => {
            const rand = Math.floor(Math.random() * 10);
            let randStr = '';
            for (let i = 0; i < 20 + rand; i++) {
                randStr += String.fromCharCode(33 + Math.floor(Math.random() * 94));
            }
            return randStr;
        };
        const randStr = generateRandomString();
        localStorage.setItem('discord_state', randStr);

        const discordOauthUrl = `${DISCORD_API_ENDPOINT}/oauth2/authorize`;
        const pageUrl = new URL(window.location.href.replace(window.location.hash, ''));
        const redirect_uri = encodeURIComponent(pageUrl.origin + pageUrl.pathname + pageUrl.search + pageUrl.hash);
        window.location = `${discordOauthUrl}?response_type=token&client_id=${DISCORD_CLIENT_ID}&state=${btoa(randStr)}&scope=${scopes.join(' ')}&redirect_uri=${redirect_uri}`;
    }

    static discordComingFrom() {
        const hash = new URLSearchParams(window.location.hash.slice(1));
        if (hash.has('error')) {
            if (hash.get('error') === 'access_denied') {
                Auth.cleanDiscordState();
            }
        }
        return hash.has('access_token');
    }

    static discordGetUser() {
        const discordUserUrl = `${DISCORD_API_ENDPOINT}/users/@me`;
        const hash = new URLSearchParams(window.location.hash.slice(1));
        if (hash.has('access_token')) {
            history.pushState('', document.title, window.location.href.replace(window.location.hash, ''));
            const access_token = hash.get('access_token');
            const token_type = hash.get('token_type');
            const hashState = hash.get('state');
            const discord_state = localStorage.getItem('discord_state');
            Auth.cleanDiscordState();
            if (discord_state !== atob(decodeURIComponent(hashState))) {
                return Promise.reject(new Error('Si è verificato un problema di sicurezza durante il login con Discord! Riprovare.'));
            } else {
                return fetch(discordUserUrl, {headers: {Authorization: `${token_type} ${access_token}`}})
                    .then(res => res.json())
                    .then(json => ({...json, token_type, access_token}))
                    .catch(console.error);
            }
        } else {
            return Promise.resolve();
        }
    }

    static cleanDiscordState() {
        localStorage.removeItem('discord_state');
    }
}
