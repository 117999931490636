import {COOKIE_ANALYTICS_NAME} from '@/config';
//import dayjs from "dayjs";

export default class CookiesPolicy {
    constructor(vue)
    {
        this.vue = vue;
    }

    isAnalyticsEnabled()
    {
        return (this.isEnabled(COOKIE_ANALYTICS_NAME));
    }

    isAnalyticsIsset()
    {
        return (this.isIsset(COOKIE_ANALYTICS_NAME));
    }

    isEnabled(cookieName)
    {
        // prendo il cookie
        const cookie = this.getCookie(cookieName);
        return (cookie === 'enabled') ? true : false;
    }

    isIsset(cookieName)
    {
        // prendo il cookie
        const cookie = this.getCookie(cookieName);
        return cookie !== null ? true : false;
    }

    getCookie(cookieName)
    {
        const c = this.vue.$cookies.get(cookieName);

        if (c === null)
        {
            // lo creo
            this.vue.$cookies.set(cookieName, 'not-set',(60 * 60 * 24 * 365 * 5));
            return null;
        }

        // fix per allungare la data di scadenza
        this.vue.$cookies.set(cookieName, c, (60 * 60 * 24 * 365 * 5));

        if (c === 'not-set')
        {
            return null;
        }

        return c;
    }

    setAnalyticsStatus(value)
    {
        const key = value === true ? 'enabled' : 'disabled';
        this.vue.$cookies.set(COOKIE_ANALYTICS_NAME, key, (60 * 60 * 24 * 365 * 5));
    }
}