import axios from 'axios';
import dayjs from 'dayjs';
import PromiseConcurrentQueue from '@/libraries/PromiseConcurrentQueue';
import Auth from '@/libraries/Auth';

import {GRANT_TYPE, CLIENT_ID as wrimoItaliaClient, CLIENT_SECRET as wrimoItaliaSecret} from '@/config';

const wrimoItaliaEndpoint = '';

const pcq = new PromiseConcurrentQueue(50, 1000);

export default class Api {
    async _axios(url, options, withAuth = true) {
        if (!options.headers) {
            options.headers = {};
        }

        const headers = this._returnOptionsHeaders();
        options.headers = {...options.headers, ...headers};
        options.responseType = 'json';
        options.url = `${wrimoItaliaEndpoint}/api${url}`;
        return pcq.enqueue(axios, null, options).then((resp) => resp.data).catch((error) => {
            // console.error('API ERROR', JSON.stringify(error));
            console.error('API ERROR', JSON.stringify(error.response));


            return Promise.reject({
                message: error.response.statusText,
                status: error.response.status,
                code: error.response.status,
                ...error.response.data,
            });
        });
    }

    _returnOptionsHeaders(withAuth = true)
    {
        let headers = {};

        if (withAuth) {
            const token = this._getToken();
            headers['Authorization'] = `Bearer ${token}`;
        }

        const now = dayjs();
        
        headers['Date-ISO'] = now.toISOString();
        headers['Date-Locale'] = now.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        headers['Date-Offset'] = now.format('Z');
        headers['Accept'] = 'application/json';

        return headers;
    }

    _getToken() {
        const token = Auth.getToken();
        if (token) {
            return token.access_token;
        }
        window.location.url = '/login';
    }

    getMe() {
        const options = {method: 'GET'};
        return this._axios(`/users/@me`, options);
    }

    getUser(userId) {
        const options = {method: 'GET'};
        return this._axios(`/users/${userId}`, options);
    }

    updateMe(body) {
        const options = {
            method: 'PATCH',
            data: body,
        };

        return this._axios(`/users/@me`, options);
    }

    deleteMyAvatar()
    {
        const options = {
            method: 'DELETE'
        };

        return this._axios(`/users/@me/avatar`, options);
    }

    forgotPassword(body) {
        const options = {
            method: 'POST',
            data: body,
        };

        return this._axios(`/password/forgot`, options);
    }

    findPasswordToken(resetToken) {
        const options = {method: 'GET'};
        return this._axios(`/password/find/${resetToken}`, options);
    }

    confirmEmail(confirmToken) {
        const options = {method: 'GET'};
        return this._axios(`/email/confirm/${confirmToken}`, options);
    }

    sendConfirmEmail() {
        const options = {
            method: 'POST'
        };
        return this._axios(`/email/send`, options);
    }

    passwordReset(body) {
        const options = {
            method: 'POST',
            data: body,
        };

        return this._axios(`/password/reset`, options);
    }

    linkDiscord(body) {
        const options = {
            method: 'POST',
            data: body,
        };

        return this._axios(`/users/@me/discord`, options);
    }

    unLinkDiscord() {
        const options = {method: 'DELETE'};
        return this._axios(`/users/@me/discord`, options);
    }

    getUsers() {
        const options = {method: 'GET'};
        return this._axios('/users', options);
    }

    getEditions() {
        const options = {method: 'GET'};
        return this._axios(`/editions`, options);
    }

    getEdition(editionId) {
        const options = {method: 'GET'};
        return this._axios(`/editions/${editionId}`, options);
    }

    getActiveEdition(warmup = true) {
        const options = {method: 'GET'};
        return this._axios(`/editions/active?warmup=${warmup}`, options);
    }

    getProjects(sort='desc') {
        const options = {method: 'GET'};
        return this._axios(`/users/@me/projects?sort=${sort}`, options);
    }

    getEditionProjects(editionId, sort='desc')
    {
        const options = {method: 'GET'};
        return this._axios(`/editions/${editionId}/projects?sort=${sort}`, options);
    }

    getActiveProject() {
        const options = {method: 'GET'};
        return this._axios(`/users/@me/projects/active`, options);
    }

    getProject(projectId) {
        const options = {method: 'GET'};
        return this._axios(`/projects/${projectId}`, options);
    }

    getUserProjects(userId, sort='desc') {
        const options = {method: 'GET'};
        return this._axios(`/users/${userId}/projects?sort=${sort}`, options);
    }


    createProject(body) {
        const options = {
            method: 'POST',
            data: body,
        };

        return this._axios('/projects', options);
    }

    updateProject(projectId, body) {
        const options = {
            method: 'PATCH',
            data: body,
        };

        return this._axios(`/projects/${projectId}`, options);
    }

    logProjectView(project)
    {
        const options = {
            method: 'POST',
            data: {
                'action': 'viewOtherUserProject',
                'related_object_id' : project.id,
                'edition_id' : project.edition_id
            },
        };

        return this._axios('/actions', options);
    }


    getProjectWordcounts(projectId) {
        const options = {method: 'GET'};
        return this._axios(`/projects/${projectId}/wordcounts`, options);
    }

    createWordcount(project, body) {
        body = {
            ...body,
            project_id: project.id,
            origin: 'manual',
        };

        const options = {
            method: 'POST',
            data: body,
        };

        return this._axios('/wordcounts', options);
    }

    updateWordcount(wordcountId, body) {
        body = {
            ...body,
            origin: 'manual'
        };

        const options = {
            method: 'PATCH',
            data: body,
        };

        return this._axios(`/wordcounts/${wordcountId}`, options);
    }

    deleteWordCount(wordcountId) {
        const options = {method: 'DELETE'};
        return this._axios(`/wordcounts/${wordcountId}`, options);
    }

    getGenres() {
        const options = {method: 'GET'};
        return this._axios(`/genres`, options);
    }

    getLeagues(editionId) {
        const options = {method: 'GET'};
        return this._axios(`/editions/${editionId}/leagues`, options);
    }

    getLeague(leagueId) {
        const options = {method: 'GET'};
        return this._axios(`/leagues/${leagueId}`, options);
    }

    getUserLeague() {
        const options = {method: 'GET'};
        return this._axios(`/users/@me/league`, options);
    }

    getUserWordWars(editionId) {
        const options = {method: 'GET'};
        return this._axios(`/users/@me/editions/${editionId}/wordwars`, options);
    }

    getUserWordWar(wordWarId) {
        const options = {method: 'GET'};
        return this._axios(`/wordwars/${wordWarId}`, options);
    }

    getUserChallenges(editionId) {
        const options = {method: 'GET'};
        return this._axios(`/users/@me/editions/${editionId}/challenges`, options);
    }

    /*getUserBadges(editionId, view = 'tree') {
        const options = {method: 'GET'};
        return this._axios(`/users/@me/editions/${editionId}/badges?view=${view}`, options);
    }*/

    getCurrentUserBadges(type='badge', editionId=null, view = 'tree')
    {
        const options = {method: 'GET'};
        let url = `/users/@me`;

        if(editionId !== null)
        {
            url += `/editions/${editionId}`;
        }
        return this._axios(`${url}/badges?view=${view}&type=${type}`, options);
    }

    getUserBadges(userId, type='badge', editionId=null, view = 'tree')
    {
        const options = {method: 'GET'};
        let url = `/users/${userId}`;

        if(editionId !== null)
        {
            url += `/editions/${editionId}`;
        }

        return this._axios(`${url}/badges?view=${view}&type=${type}`, options);
    }

    getUserTitles() {
        const options = {method: 'GET'};
        return this._axios(`/users/@me/titles`, options);
    }

    getUserAvailableTags() {
        const options = {method: 'GET'};
        return this._axios(`/users/@me/tags/available`, options);
    }

    /*addUserBadgeByCode(userId, body) {
        const options = {
            method: 'POST',
            data: body,
        };
        return this._axios(`/users/${userId}/badges`, options);
    }*/

    addUserCode(userId, body) {
        const options = {
            method: 'POST',
            data: body,
        };
        return this._axios(`/users/${userId}/codes`, options);
    }

    getNotifications(limit) {
        const options = {method: 'GET'};
        return this._axios(`/users/@me/notifications${limit ? `?limit=${limit}` : ''}`, options);
    }

    setNotificationRead(notificationId) {
        const options = {method: 'PATCH'};
        return this._axios(`/users/@me/notifications/${notificationId}/read`, options);
    }

    setNotificationShown(notificationId) {
        const options = {method: 'PATCH'};
        return this._axios(`/users/@me/notifications/${notificationId}/shown`, options);
    }

    setAllNotificationRead() {
        const options = {method: 'PATCH'};
        return this._axios(`/users/@me/notifications/read`, options);
    }

    login(email, password, body) {
        body = {
            client_id: wrimoItaliaClient,
            client_secret: wrimoItaliaSecret,
            grant_type: GRANT_TYPE,
            username: email,
            password: password,
            scope: '*',
            ...body
        };

        const options = {
            method: 'POST',
            data: body,
        };

        return this._axios('/oauth/token', options, false);
    }

    register(body) {
        const options = {
            method: 'POST',
            data: body,
        };
        return this._axios('/register', options, false);
    }

    returnApiUrl(url)
    {
        return `${wrimoItaliaEndpoint}/api${url}`;
    }
}

export const api = new Api();